
<template>
    <section>
        <b-tabs>
            <b-tab-item label="Datasets">
              <section class="is-fullheight is-fullwidth">
                <div class="columns is-1 ">
                    <div class="column">
                      <div>
                      <b-field :grouped=true class="is-1">
                        <b-input placeholder="Search..."
                                 type="search"
                                 icon-pack="fas"
                                 v-model="q"
                                 @keyup.native.enter="loadAsyncData()"
                                 icon-clickable
                                 @icon-click="loadAsyncData()"
                                 icon="search">
                        </b-input>

                        <b-select @change="loadAsyncData()" v-model="qc">
                          <option value="ID">ID</option>
                          <option value="dname">Name</option>
                          <option value="dserial">Serial</option>
                          <option value="createdAt">Date</option>
                        </b-select>
                      </b-field>

                      <b-table
                          :data="data"
                          ref="table"
                          :loading="loading"
                          class="my-table is-medium is-1"
                          :striped="true"
                          :hoverable="true"
                          :selected.sync="selected"
                          :debounce-search="1000"
                          :sticky-header="true"
                          :height="tableHeight"
                          @resize="onResize"
                          focusable
                          @click="clickedDetails"

                          :opened-detailed="defaultOpenedDetails"
                          detailed
                          detail-key="id"
                          @details-open="(row) => closeAllOtherDetails(row)"
                          :show-detail-icon="false"
                          paginated
                          backend-pagination
                          :total="total"
                          :per-page="pageSize"
                          @page-change="onPageChange"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page"
                          backend-sorting
                          :default-sort-direction="defaultSortOrder"
                          :default-sort="[sortField, sortOrder]"
                          @sort="onSort">

                        <b-table-column field="id" label="ID" v-slot="props" width="40" sticky numeric sortable class="is-sticky-column-one">
                          <b-tooltip v-if="props.row.sentactive" :label="'service request'" position="is-right">
                            <span class="tag is-success is-status is-small is-sticky-column-two">{{ props.row.id }}</span>
                            <span style="font-size: 0.5em; color: #ff99aa;" class="is-small">service req.</span>
                          </b-tooltip>
                          <span v-else class="tag is-success is-status is-small is-sticky-column-one">{{ props.row.id }}</span>
                        </b-table-column>

                        <b-table-column field="dname" label="Device Name" sortable centered v-slot="props">
                          {{ props.row.dname }}
                        </b-table-column>

                        <b-table-column field="dserial" label="Serial ADoc" sortable centered v-slot="props">
                          {{ props.row.dserial }}
                        </b-table-column>

                        <b-table-column field="createdAt" label="Date" sortable centered v-slot="props">
                          {{ new Date(props.row.createdAt).toLocaleDateString('de-DE')}}
                        </b-table-column>

                        <b-table-column field="createdAt" label="Time" centered v-slot="props">
                          {{ new Date(props.row.createdAt).toLocaleTimeString('de-DE')}}
                        </b-table-column>

                      </b-table>
                      </div>
                    </div>

                    <div class="column">
                      <b-message title="Device" type="is-primary-light" class="no-paddding is-small no-margin is-marginless" :closable="false">
                          <b-table
                            :data="selectedDetails"
                            ref="deviceTable"
                            narrowed
                            card-layout
                            :hoverable="true">
                            <b-table-column field="dname" label="Device Name" sortable centered v-slot="props">
                              {{ props.row.dname }}
                            </b-table-column>
                            <b-table-column field="dserial" label="Serial Akkudoc" sortable centered v-slot="props">
                              {{ props.row.dserial }}
                            </b-table-column>
                            <b-table-column field="revision" label="Revision No." centered v-slot="props">
                              {{ props.row.revision }}
                            </b-table-column>
                            <b-table-column field="firmwareversion" label="FW Version" sortable centered v-slot="props">
                              {{ props.row.firmwareversion }}
                            </b-table-column>
                            <b-table-column field="firmwarevariante" label="FW Variation" sortable centered v-slot="props">
                              {{ props.row.firmwarevariante }}
                            </b-table-column>
                            <b-table-column field="dpassword" label="Password" centered v-slot="props">
                              {{ props.row.dpassword }}
                            </b-table-column>
                            <b-table-column field="psantitheftenabled" label="AVAT" centered v-slot="props">
                              {{ props.row.psantitheftenabled }}
                            </b-table-column>
                            <b-table-column field="killswitchenabled" label="IKOS" centered v-slot="props">
                              {{ props.row.killswitchenabled }}
                            </b-table-column>
                            <b-table-column field="soc" label="SOC" centered v-slot="props">
                              {{ props.row.soc }}%
                            </b-table-column>
                            <b-table-column field="dstatus" label="State" centered v-slot="props">
                              {{ props.row.dstatus }}
                            </b-table-column>
                            <b-table-column field="reason" label="Reason" centered v-slot="props">
                              {{ props.row.reason }}
                            </b-table-column>
                          </b-table>
                        </b-message>
                      <b-message title="Current" type="is-primary-light" class="no-paddding scrollableContent is-small is-marginless" :closable="false">
                        <b-table
                            :data="selectedCurrentInfo"
                            ref="currentTable"
                            narrowed
                            :mobile-cards="false"
                            :sticky-header="true"
                            class=""
                            :hoverable="true">
                          <b-table-column field="verticalHeader" label="" class="verticalHeader" sticky v-slot="props">
                            {{ props.row.verticalHeader }}
                          </b-table-column>
                          <b-table-column field="now" label="NOW" centered v-slot="props" >
                            <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow(props.row.verticalHeader, 'now', props.row.now) && !shouldHighlightRed(props.row.verticalHeader, 'now', props.row.now),
  'highlighted-value-red': shouldHighlightRed(props.row.verticalHeader, 'now', props.row.now)
}">
                              {{ props.row.now  }}
                            </span>
                          </b-table-column>
                          <b-table-column field="min" label="MIN" centered v-slot="props">
                            <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow(props.row.verticalHeader, 'min', props.row.min) && !shouldHighlightRed(props.row.verticalHeader, 'min', props.row.min),
  'highlighted-value-red': shouldHighlightRed(props.row.verticalHeader, 'min', props.row.min)
}">
                              {{ props.row.min }}
                            </span>
                          </b-table-column>
                          <b-table-column field="max" label="MAX" centered v-slot="props">
                            <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow(props.row.verticalHeader, 'max', props.row.max) && !shouldHighlightRed(props.row.verticalHeader, 'max', props.row.max),
  'highlighted-value-red': shouldHighlightRed(props.row.verticalHeader, 'max', props.row.max)
}">
                              {{ props.row.max }}
                            </span>
                          </b-table-column>
                        </b-table>
                      </b-message>

                    </div>
                    <div class="column">
                      <b-message title="Lifecycle" type="is-primary-light" class="no-paddding is-small is-marginless" :closable="false">
                        <b-tabs size="is-small" class="block is-paddingless">
                          <b-tab-item label="Info" class="is-paddingless">
                          <b-table
                              :data="selectedLifecycleStarttime"
                              ref="lifecycleTable"
                              narrowed
                              card-layout
                              deep
                              :hoverable="true">
                            <b-table-column field="lifecycleStart" label="Lifecycle start (0h)" centered v-slot="" :visible="lifecycleDataLoading">
                               loading ...
                            </b-table-column>
                            <b-table-column field="lifecycleStart" label="Lifecycle start (0h)" centered v-slot="props" :visible="!lifecycleDataLoading">
                              {{ new Date(props.row.lifecycleStart).toLocaleDateString('de-DE') }} {{ new Date(props.row.lifecycleStart).toLocaleTimeString('de-DE')}}
                            </b-table-column>
                            <b-table-column field="dtcycle" label="Δt Cycle" centered v-slot="props">
                              {{ props.row.deltaTCycleHours }} hours
                            </b-table-column>
                            <b-table-column field="timeSinceBoot" label="time since boot" centered v-slot="props">
                              {{ props.row.dayssinceboot }} days
                            </b-table-column>
                            <b-table-column field="timeAwake" label="time awake" centered v-slot="props">
                              {{ props.row.hoursawake }} hours
                            </b-table-column>
                            <b-table-column field="durationDeepDecharge" label="deep discharge" centered v-slot="" :visible="deepDechargeDataLoading">
                              loading ...
                            </b-table-column>
                            <b-table-column field="durationDeepDecharge" label="deep discharge" centered v-slot="props" :visible="!deepDechargeDataLoading">
                              {{ props.row.durationDeepDeecharge > -1 ? props.row.durationDeepDeecharge + " days" : "n.v."}}
                            </b-table-column>
                            <b-table-column field="startCounter" label="Start counter" centered v-slot="props">
                              {{ props.row.startCounter }}
                            </b-table-column>
                            <b-table-column field="chargeCounter" label="Charge counter" centered v-slot="props">
                              {{ props.row.chargeCounter }}
                            </b-table-column>
                          </b-table>
                          </b-tab-item>
                            <b-tab-item label="Buckets" class="is-paddingless">
                              <b-table :data="selectedBuckets" narrowed hoverable>
                                  <b-table-column field="socLabel" label="" centered v-slot="props">
                                    <span class="label-color">
                                    {{ props.row.socLabel }}
                                    </span>
                                  </b-table-column>
                                  <b-table-column field="soc" label="SOC" centered v-slot="props">
                                    {{ props.row.soc }}
                                  </b-table-column>
                                  <b-table-column field="temp" label="Temp" centered v-slot="props">
                                    {{ props.row.temp }}
                                  </b-table-column>
                                  <b-table-column field="tempLabel" label="" centered v-slot="props">
                                    <span class="label-color">
                                    {{ props.row.tempLabel }}
                                      </span>
                                  </b-table-column>
                              </b-table>
                            </b-tab-item>
                          </b-tabs>

                      </b-message>

                      <b-message :title=selectedCustomerID.toString() type="is-primary-light" class="no-paddding is-small is-marginless" :closable="false">
                        <template #header>
                          <span v-if="selectedIsReseller">Reseller № {{selectedCustomerID.toString()}}</span>
                          <span v-else>Customer № {{selectedCustomerID.toString()}}</span>
                        </template>
                        <b-tabs size="is-small" class="block is-paddingless">
                          <b-tab-item label="Customer Info" class="is-paddingless">
                            <b-table
                                :data="selectedCustomerData"
                                ref="customerTable"
                                narrowed
                                :striped="true"
                                card-layout
                                :hoverable="true">
                              <b-table-column field="firstname" label="First name" centered v-slot="props">
                                {{ props.row.firstName }}
                              </b-table-column>
                              <b-table-column field="lastname" label="Last name" centered v-slot="props">
                                {{ props.row.lastName }}
                              </b-table-column>
                              <b-table-column field="email" label="Email" centered v-slot="props">
                                {{ props.row.email }}
                              </b-table-column>
                              <b-table-column field="phone" label="Phone" centered v-slot="props">
                                {{ props.row.phone }}
                              </b-table-column>
                              <b-table-column field="company" label="Company" centered v-slot="props">
                                {{ props.row.company }}
                              </b-table-column>
                              <b-table-column field="country" label="Country" centered v-slot="props">
                                {{ props.row.country }}
                              </b-table-column>
                            </b-table>
                          </b-tab-item>
                          <b-tab-item label="Vehicle">
                            <b-table
                                :data="selectedVehicleData"
                                ref="vehicleTable"
                                narrowed
                                card-layout
                                :sticky-header="true"
                                height="150px"
                                :hoverable="true">
                              <b-table-column field="brand" label="Brand" centered v-slot="props">
                                {{ props.row.brand }}
                              </b-table-column>
                              <b-table-column field="model" label="Model" centered v-slot="props">
                                {{ props.row.model }}
                              </b-table-column>
                              <b-table-column field="buildYear" label="Build year" centered v-slot="props">
                                {{ props.row.build }}
                              </b-table-column>
                              <b-table-column field="engine" label="Engine" centered v-slot="props">
                                {{ props.row.engine }}
                              </b-table-column>
                              <b-table-column field="fuel" label="Fuel" centered v-slot="props">
                                {{ props.row.fuel }}
                              </b-table-column>
                              <b-table-column field="engineConcept" label="Engine concept" centered v-slot="props">
                                {{ props.row.engineConcept }}
                              </b-table-column>
                              <b-table-column field="engineCylinders" label="Cylinders" centered v-slot="props">
                                {{ props.row.engineCylinders }}
                              </b-table-column>
                              <b-table-column field="engineSize" label="Engine size" centered v-slot="props">
                                {{ props.row.engineSize }}
                              </b-table-column>
                              <b-table-column field="usage" label="Usage" centered v-slot="props">
                                {{ props.row.usage }}
                              </b-table-column>
                              <b-table-column field="consumersStandby" label="Consumers standby" centered v-slot="props">
                                {{ props.row.consumersStandby }}
                              </b-table-column>
                              <b-table-column field="electricalPeriphery" label="Electrical periphery" centered v-slot="props">
                                {{ props.row.electricalPeriphery }}
                              </b-table-column>
                              <b-table-column field="info" label="Info" centered v-slot="props">
                                {{ props.row.info }}
                              </b-table-column>
                            </b-table>
                          </b-tab-item>
                        </b-tabs>

                        <b-message v-if="selectedCustomerMessage !== ''" title="Customer message" type="is-danger" class="no-paddding is-small is-marginless" :closable="false">
                          {{ selectedCustomerMessage }}
                        </b-message>
                        <b-message v-else title="Customer message" type="is-primary-light" class="no-paddding is-small is-marginless" :closable="false">
                          {{ selectedCustomerMessage }}
                        </b-message>

                      </b-message>

                    </div>

                    <div class="column">
                      <b-message title="History" type="is-primary-light" class="no-paddding is-small is-marginless" :closable="false">
                          <b-table
                              :data="selectedPshistoryData"
                              ref="historyTable"
                              class="is-marginless is-paddingless maxwidth"
                              narrowed
                              :mobile-cards="false"
                              :sticky-header="true"
                              :hoverable="true"
                              :default-sort-direction="defaultSortOrder"
                              :default-sort="['ts', sortOrder]">

                            <b-table-column field="ts" label="Time" centered sortable v-slot="" :visible="pshistoryDataLoading">loading ...</b-table-column>
                            <b-table-column field="ts" label="Time" centered sortable v-slot="props" :visible="!pshistoryDataLoading">
                              {{ typeof props.row.time === "undefined" ? "loading data ... " : props.row.ts ? props.row.ts : new Date(props.row.time).toLocaleString('de-DE') }}
<!--                              {{  typeof props.row.time === "undefined" ? "loading data ... " :  new Date(props.row.time).toLocaleString('de-DE') }}-->
                            </b-table-column>
                            <b-table-column field="status" label="Status" centered sortable v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="status" label="Status" centered sortable v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="status" label="Status" sortable v-slot="props" :visible="!pshistoryDataLoading">
                              {{props.row.state}}
                            </b-table-column>
                            <b-table-column field="reason" label="Reason" sortable centered v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="reason" label="Reason" sortable centered v-slot="props" :visible="!pshistoryDataLoading">
                              {{ props.row.reason }}
                            </b-table-column>
                            <b-table-column field="value" label="Value" sortable centered v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="value" label="Value" sortable centered v-slot="props" :visible="!pshistoryDataLoading">
                              {{ props.row.value }}
                            </b-table-column>
                            <b-table-column field="current" label="Current" sortable centered v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="current" label="Current" sortable centered v-slot="props" :visible="!pshistoryDataLoading">
                              {{ props.row.current }}
                            </b-table-column>
                            <b-table-column field="voltages" label="Voltages" sortable centered v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="voltages" label="Voltages" sortable centered v-slot="props" :visible="!pshistoryDataLoading">
                              <a>
                                {{ props.row.voltages ? props.row.voltages.toString() : "" }}
                              </a>
                            </b-table-column>
                            <b-table-column field="temps" label="Temps" sortable centered v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="temps" label="Temps" sortable centered v-slot="props" :visible="!pshistoryDataLoading">
                              <b-tooltip :label="props.row.temperatures" position="is-left" class="custom-tooltip">
                                <a>
                                  {{ props.row.temperatures ? props.row.temperatures.toString() : ""  }}
                                </a>
                              </b-tooltip>
                            </b-table-column>

                          </b-table>
                        </b-message>
                      <b-message title="Selftest" type="is-primary-light" class="no-paddding is-small is-marginless" :closable="false">
                        <b-table
                            :data="selectedSelftestResult"
                            ref="currentTable"
                            narrowed
                            card-layout
                            :sticky-header="true"
                            height="260px"
                            class=""
                            :hoverable="true">
                          <b-table-column field="bmsCellOv" label="bmsCellOv" v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsCellOv == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsCellOv == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsCellUv" label="bmsCellUv" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsCellUv == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsCellUv == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsCbOpen" label="bmsCbOpen" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsCbOpen == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsCbOpen == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsCbShort" label="bmsCbShort" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsCbShort == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsCbShort == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsOt" label="bmsOt" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsOt == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsOt == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsUt" label="bmsUt" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsUt == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsUt == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsFault1" label="bmsFault1" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsFault1 == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsFault1 == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsFault2a" label="bmsFault2a" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsFault2a == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsFault2a == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsFault2b" label="bmsFault2b" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsFault2b == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsFault2b == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="cellUv" label="cellUv" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].cellUv == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].cellUv == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="cellOv" label="cellOv" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].cellOv == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].cellOv == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="generic" label="generic" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].generic == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].generic == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="fs4500Diag1" label="fs4500Diag1" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].fs4500Diag1 == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].fs4500Diag1 == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="fs4500Diag2" label="fs4500Diag2" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].fs4500Diag2 == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].fs4500Diag2 == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="fs4500Diag3" label="fs4500Diag3" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].fs4500Diag3 == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].fs4500Diag3 == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="fs4500Diag4" label="fs4500Diag4" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].fs4500Diag4 == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].fs4500Diag4 == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                        </b-table>
                      </b-message>
                    </div>
                </div>
              </section>

            </b-tab-item>

            <b-tab-item label="Export">
                <template v-if="selected">
                    <pre>{{ selected }}</pre>
                </template>
                <template v-else>
                    <p>No item is selected</p>
                </template>
            </b-tab-item>
        </b-tabs>

  </section>
</template>

<script>
    export default {
        name: 'TelemetrydataManager',
        data() {
            return {
                data: [],
                selected: null,
                selectedDetails: [],
                selectedCurrentInfo: [],
                selectedCustomerData: [],
                selectedCustomerID: 'x',
                selectedIsReseller: false,
                selectedCustomerMessage: "",
                selectedVehicleData: [],
                selectedPshistoryData: [],
                selectedSelftestResult: [],
                selectedBuckets: {
                  soc: [],
                  temp: []
                },
                selectedLifecycleStarttime: [{
                  "deviceName": "",
                  "lifecycleStart": "",
                  "deltaTCycleHours": "",
                  "dayssinceboot": "",
                  "hoursawake": "",
                  "durationDeepDeecharge": "",
                  "telemetryId": ""
                }],
                lifecycleDataLoading: true,
                deepDechargeDataLoading: true,
                pshistoryDataLoading: true,
                tableHeight: (window.innerHeight - 200),
                total: 0,
                q: '',
                qc: 'dname',
                telId: this.$route.params.id,
                loading: false,
                sortField: 'id',
                sortOrder: 'desc',
                defaultSortOrder: 'desc',
                page: 1,
                pageSize: 20,
                defaultOpenedDetails: [],
                abortController: new AbortController()
            }
        },
        methods: {
          shouldHighlightRed(verticalHeader, column, value) {
            const numericValue = parseFloat(value);
            if (verticalHeader.startsWith('U cell')) {
              // Highlight voltage values for specific cells
              if (this.selectedDetails[0].maxcellvoltagered !== 0 && this.selectedDetails[0].mincellvoltagered !== 0) {
                return (numericValue <= this.selectedDetails[0].mincellvoltagered || numericValue >= this.selectedDetails[0].maxcellvoltagered);
              }
            } else if (verticalHeader === 'U total') {
              // Highlight current values for 'I total' row
              if (this.selectedDetails[0].minvoltagered !== 0 && this.selectedDetails[0].maxvoltagered !== 0) {
                return (numericValue <= this.selectedDetails[0].minvoltagered || numericValue >= this.selectedDetails[0].maxvoltagered);
              }
            } else if (verticalHeader === 'T pws') {
              return (this.selectedDetails[0].pswarnredtemp > 0 && numericValue >= this.selectedDetails[0].pswarnredtemp);
            } else if (verticalHeader === 'T cells') {
              return (this.selectedDetails[0].tempred > 0 && numericValue >= this.selectedDetails[0].tempred);
            }
            return false; // Default: Do not highlight
          },

          shouldHighlightYellow(verticalHeader, column, value) {
            const numericValue = parseFloat(value);
            if (verticalHeader.startsWith('U cell')) {
              // Highlight voltage values for specific cells
              if (this.selectedDetails[0].maxcellvoltageyellow !== 0 && this.selectedDetails[0].mincellvoltageyellow !== 0) {
                return (numericValue <= this.selectedDetails[0].mincellvoltageyellow || numericValue >= this.selectedDetails[0].maxcellvoltageyellow);
              }
            } else if (verticalHeader === 'U total') {
              // Highlight current values for 'I total' row
              if (this.selectedDetails[0].minvoltageyellow !== 0 && this.selectedDetails[0].maxvoltageyellow !== 0) {
                return (numericValue <= this.selectedDetails[0].minvoltageyellow || numericValue >= this.selectedDetails[0].maxvoltageyellow);
              }
            } else if (verticalHeader === 'T pws') {
              return (this.selectedDetails[0].pswarnyellowtemp > 0 && numericValue >= this.selectedDetails[0].pswarnyellowtemp);
            } else if (verticalHeader === 'T cells') {
              return (this.selectedDetails[0].tempyellow > 0 && numericValue >= this.selectedDetails[0].tempyellow);
            }
            return false; // Default: Do not highlight
          },

            /*
            * Load async data
            */
            loadAsyncData() {
                this.loading = true;
                this.$http.get(`/api/telemetrydata`, {
                    params: {
                        searchId: this.$route.query.id,
                        searchDName: this.$route.query.dn,
                        searchColumn: this.qc,
                        search: this.q,
                        page: this.page,
                        pageSize: this.pageSize,
                        draw: 0,
                        order: [this.sortField + ',' + this.sortOrder]
                    }
                })
                    .then((response) => {
                        console.log("response: " + response.status)
                        if (response.status === 200) {
                          this.data = response.data.data;
                          this.total = response.data.recordsTotal;
                          this.loading = false
                          console.log(response.data);
                        } else {
                          this.data = []
                          this.total = 0;
                          this.loading = false;
                        }
                    })
                    .catch((error) => {
                        this.data = [];
                        this.total = 0;
                        this.loading = false;
                        throw error
                    })
            },
            /*
            * Load customer data
            */
            loadCustomerData() {
              console.log("loadCustomerData for telemetryID " + this.selectedDetails[0].id + " ...")

              this.$http.get(`/api/telemetrydata/customer/`+ this.selectedDetails[0].id, {
                params: {
                  telemetryID: this.selectedDetails.id
                }
              })
                  .then((response) => {
                    console.log("response: " + response.status)
                    if (response.status === 200) {
                      console.log(response.data)
                      this.selectedCustomerData = [response.data];
                      this.selectedCustomerID = response.data.id
                      console.log(this.selectedCustomerData)
                    } else {
                      this.selectedCustomerData = [];
                      this.selectedCustomerID = "x"
                    }
                  })
                  .catch((error) => {
                    this.selectedCustomerData = [];
                    this.selectedCustomerID = "x"
                    throw error
                  })
            },
            /*
             * Load vehicle data
            */
            loadVehicleData() {
              console.log("loadVehicleData for telemetryID " + this.selectedDetails[0].id + " ...")

              this.$http.get(`/api/telemetrydata/vehicle/`+ this.selectedDetails[0].id, {
                params: {
                  telemetryID: this.selectedDetails.id
                }
              })
                  .then((response) => {
                    console.log("response: " + response.status)
                    if (response.status === 200) {
                      console.log(response.data)
                      this.selectedVehicleData = [response.data];
                      console.log(this.selectedVehicleData)
                    } else {
                      this.selectedVehicleData = [];
                    }

                  })
                  .catch((error) => {
                    this.selectedVehicleData = [];
                    throw error
                  })
            },
            /*
             * Load vehicle data
            */

            loadPshistoryData() {
              this.pshistoryDataLoading = true;

              console.log("loadPshistoryData for telemetryID " + this.selectedDetails[0].id + " ...")

              this.$http.get(`/api/telemetrydata/pshistory/`+ this.selectedDetails[0].id, {
                params: {
                  telemetryID: this.selectedDetails.id
                }
              }, )
                  .then((response) => {
                    console.log("response", JSON.stringify(response.status))
                    if (response.status === 200) {
                      console.log("response", JSON.stringify(response.data))

                      if (response.data[0].telemetrydataId === this.selectedDetails[0].id) {
                        this.selectedPshistoryData = response.data

                        console.log("selectedHistoryData", this.selectedPshistoryData)
                        this.pshistoryDataLoading = false;
                      }
                    } else {
                      this.selectedPshistoryData = []
                    }

                  })
                  .catch((error) => {
                    this.selectedPshistoryData = []
                    throw error
                  })
            },
            /*
             * Load vehicle data
            */
            loadSelftestResult() {
              console.log("loadSelftestResult for telemetryID " + this.selectedDetails[0].id + " ...")

              this.$http.get(`/api/telemetrydata/selftestresult/`+ this.selectedDetails[0].id, {
                params: {
                  telemetryID: this.selectedDetails.id
                }
              })
                  .then((response) => {
                    console.log("response: " + response.status)
                    if (response.status === 200) {
                      console.log(response.data)
                      this.selectedSelftestResult = [response.data];
                      console.log(this.selectedSelftestResult)
                    } else {
                      this.selectedSelftestResult = []
                    }

                  })
                  .catch((error) => {
                    this.selectedSelftestResult = [];
                    throw error
                  })
            },
            /*
             * Load lifecycle starttime data
            */
            loadLifecycleStarttime() {
              this.lifecycleDataLoading = true;

              console.log("loadLifecycleStarttime for deviceName " + this.selectedDetails[0].id + " ...")

              this.$http.get(`/api/telemetrydata/lifecycleStarttime/`+ this.selectedDetails[0].id, {
                params: {

                }
              })
                  .then((response) => {
                    console.log("response: " + response.status)
                    if (response.status === 200) {
                      console.log("lifecycleStarttime")
                      console.log(response.data)

                      if (response.data.deviceName === this.selectedDetails[0].dname) {
                        this.selectedLifecycleStarttime = [{
                          "deviceName": response.data.deviceName,
                          "lifecycleStart": response.data.lifecycleStart,
                          "deltaTCycleHours": this.selectedLifecycleStarttime[0].deltaTCycleHours,
                          "dayssinceboot": this.selectedLifecycleStarttime[0].dayssinceboot,
                          "hoursawake": this.selectedLifecycleStarttime[0].hoursawake,
                          "durationDeepDeecharge": this.selectedLifecycleStarttime[0].durationDeepDeecharge,
                          "telemetryId": this.selectedLifecycleStarttime[0].telemetrydataId,
                          "startCounter": this.selectedDetails[0].startCounter?? "n.v.",
                          "chargeCounter": this.selectedDetails[0].chargeCounter?? "n.v."
                        }]

                        console.log( this.selectedLifecycleStarttime);
                        this.lifecycleDataLoading = false;
                      }

                    } else {
                      this.selectedLifecycleStarttime.deviceName = "";
                      this.selectedLifecycleStarttime.lifecycleStart = "";
                    }
                  })
                  .catch((error) => {
                    this.selectedLifecycleStarttime.deviceName = "";
                    this.selectedLifecycleStarttime.lifecycleStart = "";
                    throw error
                  })
            },
          /*
             * Load lifecycle durationDeepDecharge data
            */
          loadDurationDeepDecharge() {
            this.deepDechargeDataLoading = true;

            console.log("loadDurationDeepDecharge for deviceName " + this.selectedDetails[0].id + " ...")

            this.$http.get(`/api/telemetrydata/durationDeepDecharge/`+ this.selectedDetails[0].id, {
              params: {

              }
            })
                .then((response) => {
                  console.log("response: " + response.status)
                  if (response.status === 200) {
                    console.log("durationDeepDecharge")
                    console.log(response.data)

                    if (response.data.telemetrydataId === this.selectedDetails[0].id) {
                      this.selectedLifecycleStarttime = [{
                        "deviceName": this.selectedLifecycleStarttime[0].deviceName,
                        "lifecycleStart": this.selectedLifecycleStarttime[0].lifecycleStart,
                        "deltaTCycleHours": this.selectedLifecycleStarttime[0].deltaTCycleHours,
                        "dayssinceboot": this.selectedLifecycleStarttime[0].dayssinceboot,
                        "hoursawake": this.selectedLifecycleStarttime[0].hoursawake,
                        "durationDeepDeecharge": response.data.durationDdDays,
                        "telemetryId": response.data.telemetrydataId,
                        "startCounter": this.selectedDetails[0].startCounter?? "n.v.",
                        "chargeCounter": this.selectedDetails[0].chargeCounter?? "n.v."
                      }]
                      console.log(this.selectedLifecycleStarttime);
                      this.deepDechargeDataLoading = false;
                    }

                  } else {
                    this.selectedLifecycleStarttime.durationDeepDeecharge = -1;
                    this.selectedLifecycleStarttime.telemetrydataId = -1;
                    this.deepDechargeDataLoading = false;
                  }
                })
                .catch((error) => {
                  this.selectedLifecycleStarttime.durationDeepDeecharge = -1;
                  this.selectedLifecycleStarttime.telemetrydataId = -1;
                  this.deepDechargeDataLoading = false;
                  throw error
                })
          },
          /*
           * convert a timestamp in seconds to absolute date, relative to a start date
           */
          convertTStoAbsouluteDateTimeString(start, ts) {
            const date = new Date(start);
            const newDate = date.setSeconds(date.getSeconds() + ts)
            console.log(newDate.toLocaleDateString('de-DE'));
            return newDate.toLocaleDateString('de-DE');
          },
            /*
             * Handle page-change event
             */
            onPageChange(page) {
                this.page = page;
                this.loadAsyncData()

            },
            /*
             * Handle sort event
             */
            onSort(field, order) {
                this.sortField = field;
                this.sortOrder = order;
                this.loadAsyncData()
            },
            /*
             * On resize event
             */
            onResize() {
              let offset = 200;
              self.tableHeight = (window.innerHeight - offset).toString() + 'px'
            },
            activateUser() {
              alert("activated")
            },
            closeAllOtherDetails(row) {
              this.defaultOpenedDetails = [row.id]
            },
            clickedDetails(row) {
              this.selectedDetails = [row]
              this.selectedLifecycleStarttime = [{
                "deviceName": "",
                "lifecycleStart": "",
                "deltaTCycleHours": this.selectedDetails[0].deltaTLifecycleHours,
                "dayssinceboot": this.selectedDetails[0].dayssinceboot,
                "hoursawake": this.selectedDetails[0].hoursawake,
                "durationDeepDeecharge": "",
                "telemetryId": "",
                "startCounter": this.selectedDetails[0].startCounter?? "n.v.",
                "chargeCounter": this.selectedDetails[0].chargeCounter?? "n.v."
              }]


                const socArray = row.socBuckets ? row.socBuckets.map((item) => {
                  return { soc: item };
                }) : [];

                const tempArray = row.tempBuckets ? row.tempBuckets.map((item) => {
                  return { temp: item };
                }) : [];

                // Determine the length of the arrays
                const length = Math.max(socArray.length, tempArray.length);

                // Generate the socLabels array
                const socLabels = Array.from({ length: 10 }, (_, index) => `${(index + 1) * 10}%`);

                // Generate the tempLabels array
                const tempLabels = Array.from({ length: 12 }, (_, index) => `${index * 10 - 20}°C`);

                // Combine the soc and temp values into a single array of objects
                const combinedArray = [];
                for (let i = 0; i < length; i++) {
                  combinedArray.push({
                    socLabel: socLabels[i] ? socLabels[i] : null,
                    soc: socArray[i] ? socArray[i].soc : null,
                    temp: tempArray[i] ? tempArray[i].temp : null,
                    tempLabel: tempLabels[i] ? tempLabels[i] : null
                  });
                }

                this.selectedBuckets = combinedArray;

              console.log("raw buckets", JSON.stringify(row.tempBuckets));
              console.log("transformed buckets", JSON.stringify(this.selectedBuckets));
              this.selectedCustomerData = []
              this.selectedVehicleData = []
              this.selectedPshistoryData = [{"ts": "loading data..."}]
              this.selectedSelftestResult = []
              this.selectedCustomerMessage = row.customermessage ? row.customermessage : ""
              this.selectedCurrentInfo =
                  [(row.v1current !== 0 && row.v1min !== 0 && row.v1max !== 0 && row.v1current !== undefined && row.v1min !== undefined && row.v1max !== undefined)  && {"verticalHeader": "U cell1", "now": row.v1current +" V", "min": row.v1min +" V", "max": row.v1max +" V"},
                    (row.v2current !== 0 && row.v2min !== 0 && row.v2max !== 0 && row.v2current !== undefined && row.v2min !== undefined && row.v2max !== undefined)  && {"verticalHeader": "U cell2", "now": row.v2current +" V", "min": row.v2min +" V", "max": row.v2max +" V"},
                    (row.v3current !== 0 && row.v3min !== 0 && row.v3max !== 0 && row.v3current !== undefined && row.v3min !== undefined && row.v3max !== undefined)  && {"verticalHeader": "U cell3", "now": row.v3current +" V", "min": row.v3min +" V", "max": row.v3max +" V"},
                    (row.v4current !== 0 && row.v4min !== 0 && row.v4max !== 0 && row.v4current !== undefined && row.v4min !== undefined && row.v4max !== undefined)  && {"verticalHeader": "U cell4", "now": row.v4current +" V", "min": row.v4min +" V", "max": row.v4max +" V"},
                    (row.v5current !== 0 && row.v5min !== 0 && row.v5max !== 0 && row.v5current !== undefined && row.v5min !== undefined && row.v5max !== undefined)  && {"verticalHeader": "U cell5", "now": row.v5current +" V", "min": row.v5min +" V", "max": row.v5max +" V"},
                    (row.v6current !== 0 && row.v6min !== 0 && row.v6max !== 0 && row.v6current !== undefined && row.v6min !== undefined && row.v6max !== undefined)  && {"verticalHeader": "U cell6", "now": row.v6current +" V", "min": row.v6min +" V", "max": row.v6max +" V"},

                    (row.v7current !== 0 && row.v7min !== 0 && row.v7max !== 0 && row.v7current !== undefined && row.v7min !== undefined && row.v7max !== undefined)  && {"verticalHeader": "U cell7", "now": row.v7current +" V", "min": row.v7min +" V", "max": row.v7max +" V"},
                    (row.v8current !== 0 && row.v8min !== 0 && row.v8max !== 0 && row.v8current !== undefined && row.v8min !== undefined && row.v8max !== undefined) && {"verticalHeader": "U cell8", "now": row.v8current +" V", "min": row.v8min +" V", "max": row.v8max +" V"},
                    (row.v9current !== 0 && row.v9min !== 0 && row.v9max !== 0 && row.v9current !== undefined && row.v9min !== undefined && row.v9max !== undefined)  && {"verticalHeader": "U cell9", "now": row.v9current +" V", "min": row.v9min +" V", "max": row.v9max +" V"},
                    (row.v10current !== 0 && row.v10min !== 0 && row.v10max !== 0 && row.v10current !== undefined && row.v10min !== undefined && row.v10max !== undefined)  && {"verticalHeader": "U cell10", "now": row.v10current +" V", "min": row.v10min +" V", "max": row.v10max +" V"},
                    (row.v11current !== 0 && row.v11min !== 0 && row.v11max !== 0 && row.v11current !== undefined && row.v11min !== undefined && row.v11max !== undefined)  && {"verticalHeader": "U cell11", "now": row.v11current +" V", "min": row.v11min +" V", "max": row.v11max +" V"},
                    (row.v12current !== 0 && row.v12min !== 0 && row.v12max !== 0 && row.v12current !== undefined && row.v12min !== undefined && row.v12max !== undefined)  && {"verticalHeader": "U cell12", "now": row.v12current +" V", "min": row.v12min +" V", "max": row.v12max +" V"},
                    (row.v13current !== 0 && row.v13min !== 0 && row.v13max !== 0 && row.v13current !== undefined && row.v13min !== undefined && row.v13max !== undefined)  && {"verticalHeader": "U cell13", "now": row.v13current +" V", "min": row.v13min +" V", "max": row.v13max +" V"},
                    (row.v14current !== 0 && row.v14min !== 0 && row.v14max !== 0 && row.v14current !== undefined && row.v14min !== undefined && row.v14max !== undefined)  && {"verticalHeader": "U cell14", "now": row.v14current +" V", "min": row.v14min +" V", "max": row.v14max +" V"},
                    (row.v15current !== 0 && row.v15min !== 0 && row.v15max !== 0 && row.v15current !== undefined && row.v15min !== undefined && row.v15max !== undefined)  && {"verticalHeader": "U cell15", "now": row.v15current +" V", "min": row.v15min +" V", "max": row.v15max +" V"},
                    (row.v16current !== 0 && row.v16min !== 0 && row.v16max !== 0 && row.v16current !== undefined && row.v16min !== undefined && row.v16max !== undefined)  && {"verticalHeader": "U cell16", "now": row.v16current +" V", "min": row.v16min +" V", "max": row.v16max +" V"},

                    {"verticalHeader": "U total", "now": row.vcurrent +" V", "min": row.vmin +" V", "max": row.vmax +" V"},
                    {"verticalHeader": "T cells", "now": row.tcurrent +" °C", "min": row.tmin +" °C", "max": row.tmax +" °C"},
                    {"verticalHeader": "T pws", "now": row.pstemp +" °C", "min": row.pstempmin +" °C", "max": row.pstempmax +" °C"},
                    {"verticalHeader": "I total", "now": row.dcurrent +" A", "min": row.currentmin +" A", "max": row.currentmax +" A"}].filter(Boolean)
              this.loadCustomerData()
              this.loadVehicleData()
              this.loadLifecycleStarttime()
              this.loadDurationDeepDecharge()
              this.loadPshistoryData()
              this.loadSelftestResult()
            }
        },
        mounted() {
            this.$http.get(`/api/user/role`).then(({data}) => {
                this.roles = data;
                this.loadAsyncData();
            });
            console.log("telId: " + this.telId);
            let self = this
            let offset = 200
            // to update b-table max-height to have a freeze header (sticky-header makes fixed max-height only regardless the screen height)
            // placed a new issue in Git, see if we get any response.
            self.$nextTick(() => {
              window.addEventListener('resize', () => {
                // debugger
                self.tableHeight = (window.innerHeight - offset).toString() + 'px' // where offset is some kind of constant margin you need from the top
              })
            })
          },
      computed: {
        console: () => console,


      },
        beforeDestroy() {
          window.removeEventListener('resize', () => {})
        },
    }
</script>

<style>
  .table-wrapper td {
    vertical-align: middle;
  }

  .my-table.table-wrapper { overflow: auto !important; }

  .scrollableContent .message-body { overflow: auto !important; }


  .is-sticky-column-one {
    background-color: #b6aba1 !important;
    color: #000000 !important;
  }
  .is-sticky-column-two {
    background-color: #f1c725 !important;
    color: #000000 !important;
  }

  .no-paddding .message-body {
    padding: 0;
  }

  .no-margin message {
    margin-bottom: 0 !important;
  }

  .little-padding {
    padding: 0px 5px 5px;
  }

  .verticalHeader {
    background: #4daaea !important;
    color: black;
  }

  .custom-tooltip .tooltip {
    position: fixed !important;
    z-index: 9999;
  }

  .maxwidth {
    max-width: 250px;
  }

  .highlighted-value-red {
    color: red !important;
  }

  .highlighted-value-yellow {
    color: #ffc600 !important;
  }

  .label-color {
    color: #939393 !important;
  }


  pre {
      background-color: inherit;
      color: inherit;
      font-size: inherit;
      overflow-x: auto;
      padding: inherit;
      white-space: nowrap;
      word-wrap: normal;
  }
  .truncate {
    white-space: nowrap !important;
    /*overflow: hidden !important;*/
    text-overflow: ellipsis !important;
  }

</style>
