<template>

    <section class="sidebar-layout">
        <div v-if="!isMobileView">
            <fab :actions="fabActions" :position="position" :bg-color="bgColor" :icon-size="iconSize" :main-icon="mainIcon"
                     @user="showUser"
                     @logout="logout"
            ></fab>
        </div>
      <NavBar v-if="isMobileView"></NavBar>
    <div class="sidebar-page ">
      <section class="sidebar-layout">
        <b-sidebar
            position="static"
            id="sidebar-left"
            type="is-light"
            shadow
            mobile="hide"
            :fullheight="true"
            :open="true">
          <div class="p-1">
            <img src="../assets/logo_big.jpg" alt="Litewerks GmbH"/>
            <b-menu>
              <b-menu-list label="">
                <b-menu-item label="Dashboard" icon="tachometer-alt" tag="router-link" to="/admin/dashboard"></b-menu-item>
                <b-menu-item label="Telemetry Data" icon="chart-line" tag="router-link" to="/admin/data"></b-menu-item>
                ................................................................................
                <b-menu-item v-if="adminUser" label="Users" icon="user" tag="router-link" to="/admin/user"></b-menu-item>
              </b-menu-list>
            </b-menu>
          </div>
        </b-sidebar>

        <div class="block">
            <div class="container admin-container">
                <router-view/>
            </div>
        </div>
      </section>
    </div>

    </section>
</template>

<script>
    import fab from 'vue-fab'
    import {mapActions, mapGetters} from "vuex";
    import NavBar from "./NavBar";

    export default {
        name: 'AdminLayout',
        components: {fab, NavBar},

        data() {
            return {
                isMobileView: false,
                bgColor: '#4daaea',
                /*bgColor: '#2196f3',*/
                position: 'top-right',
                iconSize: 'small',
                mainIcon: 'settings',
                fabActions: [
                    {
                        name: 'user',
                        icon: 'person',
                        color: '#f1c725'
                    },
                    {
                        name: 'logout',
                        icon: 'logout',
                        color: "#d70202"
                    }
                ]
            }
        },
      beforeDestroy () {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', this.onResize, { passive: true })
        }
      },
      mounted () {
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
      },
      methods:{
        ...mapGetters('user', ['isAuthorized', 'isAdmin']),
        ...mapActions('user', ['setUser']),
        showUser(){
            this.$router.push('profile')
        },
        logout: function() {
            this.$http.get('/signOut')
            this.setUser({})
            this.$router.push('/')
        },
        onResize () {
          this.isMobileView = window.innerWidth < 769
        }
      },
      computed:{
        adminUser: function() {
          return this.isAdmin();
        }
      }
    }
</script>

<style lang="scss">
    .p-1 {
        padding: 1em;
    }

    .admin-container {
        text-align: center;
        padding-top: 0px;
        padding-left: 0px;
        height: 100vh;
    }
    .sidebar-page {
        height: 100vh;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 100%;
        // min-height: 100vh;
        .sidebar-layout {
            display: flex;
            flex-direction: row;
            min-height: 100%;
            // min-height: 100vh;
        }
    }
    @media screen and (max-width: 1023px) {
        .b-sidebar {
            .sidebar-content {
                &.is-mini-mobile {
                    &:not(.is-mini-expand),
                    &.is-mini-expand:not(:hover) {
                        .menu-list {
                            li {
                                a {
                                    span:nth-child(2) {
                                        display: none;
                                    }
                                }
                                ul {
                                    padding-left: 0;
                                    li {
                                        a {
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }
                        .menu-label:not(:last-child) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 1024px) {
        .b-sidebar {
            .sidebar-content {
                &.is-mini {
                    &:not(.is-mini-expand),
                    &.is-mini-expand:not(:hover) {
                        .menu-list {
                            li {
                                a {
                                    span:nth-child(2) {
                                        display: none;
                                    }
                                }
                                ul {
                                    padding-left: 0;
                                    li {
                                        a {
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }
                        .menu-label:not(:last-child) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .is-mini-expand {
        .menu-list a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
